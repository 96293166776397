<template>
  <div class="white-container container-no-scroll">
    <h2>
      <slot></slot>
    </h2>
    <div class="news-container">
      <dynamic-news-card class="card-news" v-for="(news, index) in getPaginatedNews"
                         :is-list-view="false"
                         :content="news"
                         :key="`newsCard${index}`"/>
    </div>
    <div class="pagination__container" v-if="news.length > 3">
      <p @click="setNearPage(false)" v-if="entitiesCurrentPage > 2">
        &lt;
      </p>
      <span v-if="entitiesCurrentPage > 2">
            ...
      </span>

      <p v-for="i in getNewsPagesCount"
         v-show="Math.abs(entitiesCurrentPage - i) < 3"
         :class="{current: i - 1 === entitiesCurrentPage}"
         :key="`pagination${i}`"
         @click="changeEntitiesPage(i - 1)">
        {{ i }}
      </p>

      <span v-if="entitiesCurrentPage < getNewsPagesCount - 1">
            ...
          </span>
      <p @click="setNearPage(true)" v-if="entitiesCurrentPage < getNewsPagesCount - 1">
        &gt;
      </p>
    </div>
  </div>
</template>

<script>
import DynamicNewsCard from '../newsList/DynamicNewsCard'

export default {
  name: 'FinalForumNews',

  props: {
    tagsList: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data () {
    return {
      news: [],
      entitiesCurrentPage: 0,
      currentPage: 1
    }
  },

  watch: {
    tagsList: {
      deep: true,
      handler () {
        this.news = []
        if (this.tagsList.length) {
          this.getNewsBySlug(0)
        }
      }
    }
  },

  async mounted () {
    this.news = []
    if (this.tagsList.length) {
      this.getNewsBySlug(0)
    }
  },

  methods: {
    async getNewsBySlug (index) {
      if (index < this.tagsList.length) {
        let res = await fetch(`${this.$store.getters.getUrlApi}api/news/${this.tagsList[index]}`, {
          headers: {
            Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
          }
        })
        res = await res.json()
        this.news.push(await res.news)
        this.$forceUpdate()
        await this.getNewsBySlug(index + 1)
      }
    },

    changeEntitiesPage (page) {
      this.entitiesCurrentPage = page
    },

    setNearPage (direction) {
      if (!direction) {
        if (this.entitiesCurrentPage > 0) {
          this.entitiesCurrentPage--
        }
      } else {
        if (this.entitiesCurrentPage < this.news.length - 1) {
          this.entitiesCurrentPage++
        }
      }
    }
  },

  computed: {
    getNewsPagesCount () {
      return Math.ceil(this.news.length / 3)
    },

    getPaginatedNews () {
      return this.news.slice(this.entitiesCurrentPage * 3, this.entitiesCurrentPage * 3 + 3)
    }
  },

  components: {
    DynamicNewsCard
  }
}
</script>

<style lang="scss" scoped>

.pagination__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.1875rem;
    height: 2.1875rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #246CB7;
    border: 1px solid #d2dde8;
    border-radius: 3px;
    margin: 0 0.375rem;
    transition: all .2s ease-in-out;
    cursor: pointer;

    &:hover {
      background: #d1e0ef;
      border: 1px solid #246CB7;
    }
  }

  .current {
    background: #d1e0ef;
  }
}

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: #1F3245;
    margin-bottom: 1.25rem;
  }

  .news-wrapper {
    width: 90rem;
    margin: 0 auto;

    &__content {
      width: 61.4375rem;
      margin: 0;
    }
  }

  .news-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:after {
      content: '';
      display: flex;
      width: 26.5625rem;
    }
  }

  .news {
    .title_categories,
    .line_wrapper {
      margin-bottom: 1.25rem;
    }

    &__filter {
      margin-bottom: 2.5rem;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;
    }

    &__main {
      width: 55.375rem;
    }

    &__important {
      width: 26.625rem;

      div:not(:last-child) .card_important {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #eee;
      }

    }
  }

  /*.slider-stream__item {*/
  /*  display: flex;*/
  /*  flex-wrap: wrap;*/
  /*  ::v-deep :nth-child(2n+1){*/
  /*    margin-right: 2.1875rem;*/
  /*  }*/
  /*}*/

  .card_container {
    padding: 0 1rem;
    margin-top: 3.125rem;
    background: inherit;
    overflow: hidden;

    &__forum {
      overflow-x: auto;
      width: 100%;

      .card_wrapper {
        min-width: 10rem;
        height: 12.5rem;
        margin-right: 1.18rem;
        border: 1px solid #EBF3FF;
      }

    }

  }

  .card-forum-board {
    cursor: pointer;
  }

  .card-news {
    margin: 1rem 0;
  }

  @media screen and (max-width: 768px) {
    .card-news {
      width: auto;
    }
  }

  @media screen and (max-width: 420px) {
    .card-news {
      width: auto;
    }
  }

</style>
