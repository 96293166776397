<template>
  <section class="bg" :style="[GET_CURRENT_FORUM.forum_image!==null ?
    {background: `#253980 url(${$store.getters.getUrlApi}${forum_image}) center center no-repeat`, backgroundSize: 'cover, cover'} : '']">
    <div class="wrapper-forum container">
      <div class="main-forum">
        <h1 class="main-forum__title" v-trim="2">{{ GET_CURRENT_FORUM.title }}</h1>
        <div class="main-forum__date">
          <div>
            <p>дата</p>
            <p><span>{{ date[0] }}</span> {{ date[1] }} <span>-</span> <span>{{ date[2] }}</span> {{ date[3] }}</p>
          </div>
          <!-- <div>
            <p>время</p>
            <p><span>{{ GET_CURRENT_FORUM.time.slice(0, 5) }}</span> по МСК</p>
          </div> -->
          <div>
            <p>место</p>
            <p><span>{{ GET_CURRENT_FORUM.location }}</span></p>
          </div>
        </div>
        <div class="main-forum__buttons">
          <a target="_blank" class="main-forum__links" :href="GET_CURRENT_FORUM.url_link" v-if="GET_CURRENT_FORUM.register_open && GET_CURRENT_FORUM.url_link">Регистрация</a>
          <!-- http://www.xn--90aci0ajbadllemfl7f.xn--p1ai -->
          <a :href="`${$store.getters.getUrlApi}${GET_CURRENT_FORUM.program_url}`" class="links-download"
             v-if="GET_CURRENT_FORUM.program_url" target="_blank">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.368 8.9153C5.09309 8.58346 5.2185 8.31445 5.64922 8.31445L13.5611 8.31445C13.9913 8.31445 14.1181 8.58245 13.8423 8.9153L10.1029 13.429C9.82803 13.7608 9.38315 13.7618 9.1074 13.429L5.368 8.9153Z"
                    fill="white"/>
              <path
                d="M4.74081 17H14.5926C15.0937 17 15.5 16.5937 15.5 16.0926C15.5 15.5915 15.0937 15.1852 14.5926 15.1852H4.74081C4.23967 15.1852 3.83341 15.5915 3.83341 16.0926C3.83341 16.5937 4.23967 17 4.74081 17Z"
                fill="white"/>
              <path
                d="M8.23828 4.426L8.23828 9.09264C8.23828 9.88015 8.87669 10.5186 9.6642 10.5186C10.4517 10.5186 11.0901 9.88015 11.0901 9.09264L11.0901 4.426C11.0901 3.63849 10.4517 3.00008 9.6642 3.00008C8.87669 3.00008 8.23828 3.63849 8.23828 4.426Z"
                fill="white"/>
            </svg>
            Программа форума</a>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'ForumFinalMainScreen',
  data () {
    return {
      date: [],
      forum_image: '',
      months: [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
    }
  },
  computed: {
    GET_CURRENT_FORUM () {
      return this.$store.getters.GET_CURRENT_FORUM
    }
  },
  watch: {
    GET_CURRENT_FORUM () {
      const currentForum = this.$store.getters.GET_CURRENT_FORUM
      this.date = this.formattingDate(currentForum.date_start, currentForum.date_end)
      if (currentForum.forum_image !== null) {
        this.forum_image = currentForum.forum_image
      }
    }
  },
  methods: {
    formattingDate (start, end) {
      const date = []
      const dateStart = new Date(start)
      const dateEnd = new Date(end)
      /*
      const locale = 'ru-ru'
      const format = {
        month: 'long'
      }
      */
      if (dateStart.getMonth() === dateEnd.getMonth()) {
        date.push(dateStart.getDate())
        date.push('')
        date.push(dateEnd.getDate())
        date.push(this.months[dateEnd.getMonth()])
        // date.push(dateEnd.toLocaleString(locale, format))
      } else {
        date.push(dateStart.getDate())
        date.push(this.months[dateStart.getMonth()])
        date.push(dateEnd.getDate())
        date.push(this.months[dateEnd.getMonth()])
        // date.push(dateEnd.toLocaleString(locale, format))
      }
      return date
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  width: 100%;
  height: 27rem;
  /*background: linear-gradient(0deg, rgba(40, 62, 83, 0.8), rgba(40, 62, 83, 0.8)), url('../../assets/bg/banner_forum_final.svg') center center no-repeat;*/
  background: #253980 url('../../assets/bg/bg_final_forum_center.svg') center center no-repeat;
  background-size: cover, cover;
}

.wrapper-forum {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .main-forum__date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;

    > div {
      font-weight: 600;
      margin: 0 2rem;

      & p:nth-child(2n-1) {
        font-size: .8125rem;
        line-height: 1.23;
        text-transform: uppercase;
        /*margin: 1.2rem 0 1.6rem;*/
        margin-bottom: .625rem;
      }

      & span {
        font-size: 2.25rem;
        line-height: 1.22;
        font-weight: 600;
      }
    }
  }
}

.main-forum {
  width: 48rem;
  margin: 3rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: bold;
    font-size: 3rem;
    /*line-height: 109%;*/
    text-align: center;
    color: #FFFFFF;
    margin: 2rem 0 1.5rem 0;
  }

  &__buttons {
    margin-top: 3.125rem;
    display: flex;
  }

  &__links, a {
    display: inline-block;
    font-weight: bold;
    font-size: .875rem;
    line-height: 143%;
    color: #FFFFFF;
    background: #EB494E;
    border-radius: 3px;
    padding: .375rem 1.375rem;
    margin-right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .links-download {
    background: #ff000800;
    font-weight: normal;
    border: 1px solid white;
  }
}

@media screen and (max-width: 768px) {
  .wrapper-forum .main-forum__date {
    align-items: flex-start;
  }
  .main-forum, .main-forum__date {
    padding: 1.375rem 1.56rem 4rem 1.56rem;

    > div {
      margin: 0 !important;

      & p {
        margin: 0 !important;
      }
    }

    &__title, p {
      font-size: 1.25rem;
    }

    &__links, a {
      /*position: absolute;*/
      margin-bottom: 1rem;
      bottom: 1.25rem;
      width: 13.6rem;
      text-align: center;
    }
  }
}

@media screen and (max-width: 420px) {
  section {
    height: auto;
    background-size: inherit;
    background-position-y: top;
  }
  .main-forum {
    padding: 1.25rem .1rem 4rem;

    h1 {
      font-size: 2rem;
      margin-top: 5rem;
    }

    > div {
      flex-direction: column;
      margin: 0 !important;

      & p {
        margin: 0 !important;
      }
    }

    &__title, p {
      font-size: 1.25rem;
    }

    &__links, a {
      /*position: absolute;*/
      margin-bottom: 1rem;
      bottom: 1.25rem;
      width: 11.6rem;
      text-align: center;
    }
  }
}
</style>
