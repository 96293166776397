<template>
    <section class="program_wrapper">
        <h2>Пресс-центр</h2>
        <div v-html="GET_CURRENT_FORUM.program_text" class="ckEditor-html"></div>
    </section>
</template>

<script>
export default {
  name: 'ProgramForum',
  computed: {
    GET_CURRENT_FORUM () {
      return this.$store.getters.GET_CURRENT_FORUM
    }
  }
}
</script>

<style lang="scss" scoped>
  .ckEditor-html {
    ::v-deep {
      * {
        margin: revert;
        padding: revert;
        list-style-type: revert;
        font-size: revert;
        font-weight: revert;
      }
      p {
        color: rgba(23, 23, 23, 0.8);
        font-size: 0.875rem;
        line-height: 1.57;
        margin-bottom: 1.375rem;
        line-height: 157%;
        }
    }
  }
  .program_wrapper {
    width: 90rem;
    margin: 3.125rem auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 2.5rem 3.125rem;
    border-radius: 0.5rem;
    background: white;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 1.375rem;
    color: #1F3245;
  }

  p {
    color: rgba(23, 23, 23, 0.8);
    font-size: 0.875rem;
    line-height: 1.57;
    margin-bottom: 1.375rem;
    line-height: 157%;
  }

  @media screen and (max-width: 768px) {
    .program_wrapper {
        width: 100%;
        // margin-bottom: 2rem;
    }
  }
</style>
