import { render, staticRenderFns } from "./DynamicNewsCard.vue?vue&type=template&id=92d4ba2c&scoped=true&"
import script from "./DynamicNewsCard.vue?vue&type=script&lang=js&"
export * from "./DynamicNewsCard.vue?vue&type=script&lang=js&"
import style0 from "./DynamicNewsCard.vue?vue&type=style&index=0&id=92d4ba2c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92d4ba2c",
  null
  
)

export default component.exports