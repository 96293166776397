<template>
  <section class="program_wrapper" v-if="GET_CURRENT_FORUM.questions">
    <div>
      <!-- <div v-html="GET_CURRENT_FORUM.program_text" class="ckEditor-html"></div> -->
<!--      <h2>Программа</h2>-->
<!--      <p>Программа форума «Сообщество» включает в себя два рабочих дня, в течение которых будет предоставлена-->
<!--        возможность принять участие в новых для мероприятия форматах: ted-talk, public talk, talk-show, «открытый-->
<!--        микрофон» и иных форматах.</p>-->
      <p class="p2">
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.1" cx="22.5" cy="22.5" r="22.5" fill="#2AB91E"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M23 14C20.0001 14 16.9999 14 14 14C14 17.0001 14 20.0001 14 23C14 27.9705 18.0293 32 23 32C27.9707 32 32 27.9705 32 23C32 18.0293 27.9707 14 23 14ZM24.2328 28.9211H21.9247V26.674H24.2325V28.9211H24.2328ZM27.0088 21.8527C26.7213 22.2951 26.1066 22.8972 25.1652 23.6595C24.6776 24.0541 24.3752 24.3711 24.2573 24.6112C24.1396 24.8508 24.0865 25.2801 24.0975 25.8984H22.003C21.9976 25.6053 21.9947 25.4266 21.9947 25.3629C21.9947 24.7019 22.107 24.1578 22.3315 23.7315C22.5561 23.3049 23.0049 22.8254 23.6784 22.2924C24.3516 21.7593 24.7541 21.4102 24.8855 21.2448C25.0882 20.9838 25.1895 20.6958 25.1895 20.3812C25.1895 19.944 25.0101 19.5696 24.6513 19.2578C24.2928 18.9457 23.8098 18.7898 23.202 18.7898C22.6164 18.7898 22.1263 18.9523 21.7319 19.2776C21.3375 19.6027 21.0666 20.0986 20.9187 20.7649L18.7999 20.509C18.8602 19.5548 19.2776 18.7446 20.0523 18.0781C20.8269 17.4119 21.8439 17.0784 23.1035 17.0784C24.4287 17.0784 25.4826 17.4157 26.2656 18.0903C27.0484 18.7646 27.4399 19.5494 27.4399 20.4451C27.4397 20.9412 27.2961 21.4104 27.0088 21.8527Z"
                fill="#2AB91E"/>
        </svg>
        Пройти опрос
      </p>
      <p>Просим Вас принять участие в опросе, который проводится в рамках подготовки Ежегодного доклада Общественной
        палаты Российской Федерации о состоянии гражданского общества.</p>
      <router-link :to="GET_CURRENT_FORUM.program_link"> Пройти опрос</router-link>
    </div>
    <!-- <div class="program-download">
      <div class="partner-info">
      <div class="img_block"></div>
      <div class="info_block">
        <p>{{program_link.title}}</p>
        <download-file :dataFile="{url: program_link.url, size: program_link.size, type: program_link.type}"
                       class="download-file"/>
      </div>
      </div>
      <img src="/assets/bg/bg_final_forum_program.svg" alt="">
    </div> -->
  </section>
</template>

<script>
// import DownloadFile from '@/components/DownloadFile'

export default {
  name: 'FinalForumProgram',
  props: {
    link: {
      type: String,
      required: true,
      default: '/feedback'
    },
    program_link: {
      type: Object,
      required: true,
      default: () => ({
        title: 'Программа мероприятия',
        url: '/',
        size: 217,
        type: 'PDF'
      })

    }
  },
  components: {
    // DownloadFile
  },
  computed: {
    GET_CURRENT_FORUM () {
      return this.$store.getters.GET_CURRENT_FORUM
    }
  }
}
</script>

<style lang="scss" scoped>
  .ckEditor-html {
    ::v-deep {
      * {
        margin: revert;
        padding: revert;
        list-style-type: revert;
        font-size: revert;
        font-weight: revert;
      }
    }
    p {
      color: rgba(23, 23, 23, 0.8);
      font-size: 0.875rem;
      line-height: 1.57;
      margin-bottom: 1.375rem;
      line-height: 157%;
    }
  }
  .program_wrapper {
    width: 90rem;
    margin: 3.125rem auto;
    display: flex;
    justify-content: space-between;

    > div {
      padding: 2.5rem 3.125rem;
      border-radius: 0.5rem;
      background: white;
      width: 30%;
    }

    > div:first-child {
      width: 100%; // 70%
      // margin-right: 2rem;

      & a {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.25rem;
        color: #FFFFFF;
        background: #246CB7;
        padding: .5rem 1.88rem .44rem 1.88rem;
        border-radius: .187rem;
        margin-top: auto;

        &:hover {
          background: #246cb780;
        }
      }
    }

    .program-download {
      /*background: url('../../assets/bg/bg_final_forum_program.svg') center center no-repeat, white;*/
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    margin-bottom: 1.375rem;
    color: #1F3245;
  }

  p {
    color: rgba(23, 23, 23, 0.8);
    font-size: 0.875rem;
    line-height: 1.57;
    margin-bottom: 1.375rem;
    line-height: 157%;
  }

  .p2 {
    // margin-top: 2.5rem;
    color: #1F3245;
    font-weight: bold;

  }

  .partner-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.875rem;
  }

  .partner-info:last-child {
    margin-bottom: 0;
  }

  .img_block {
    position: relative;
    width: 2.82rem;
    height: 2.82rem;
    border-radius: 50%;
    background: url('../../assets/icons/document_icon.svg') center center no-repeat;
    background-size: 1rem;
    background-color: #eaf1f8;
  }

  .block_partner:last-child :last-child {
    margin-bottom: 0;
  }

  .info_block {
    margin-left: 1rem;
    width: calc(100% - 1rem - 2.82rem);

    p {
      font-weight: 600;
      color: #1F3245;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .program_wrapper {
      flex-direction: column;
      width: 100%;

      > div:first-child {
        width: 100%;
        margin-bottom: 2rem;
      }

      > .program-download {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 420px) {

  }
</style>
