<template>
  <div class="white-container">
    <h2>О форуме</h2>
    <tricolor-line class="forum_container__tricolor"/>
    <div class="section">
      <article>
        <header>
          <h2>Приглашаем к участию</h2>
        </header>
        <p class="block_text_link" v-html="GET_CURRENT_FORUM.about_forum_left_block">
        </p>

<!--        <p v-trim="4">К участию в форуме приглашаются гражданские активисты, некоммерческие организации, социальные-->
<!--          предприниматели, представители бизнеса, муниципальной и региональной власти, журналисты</p>-->
<!--        <p>Организатор форума</p>-->
<!--        <p class="p2 blue_link">Общественная палата Российской Федерации</p>-->
      </article>
      <div>
        <p class="p2" v-html="GET_CURRENT_FORUM.about_forum_text">
        </p>
<!--        <p class="p2">-->
<!--          Итоговый форум «Сообщество» в связи со сложной эпидемиологической ситуацией на территории Российской Федерации-->
<!--          пройдет преимущественно в онлайн-формате через персонализированную digital-платформу. Более подробная-->
<!--          информация будет представлена на данной странице.-->
<!--        </p>-->
<!--        <p class="p2">-->
<!--          Вы можете связаться с пресс-службой Общественной палаты РФ-->
<!--        </p>-->
        <div class="quick_contact">
          <p>Телефон <br/><span class="telephone blue_link">{{ GET_CURRENT_FORUM.about_forum_phones }}</span></p>
          <p>E-mail<br/><span class="blue_link">{{ GET_CURRENT_FORUM.about_forum_emails }}</span></p>
          <p>Адрес <br> <span class="blue_link">{{GET_CURRENT_FORUM.address}}</span></p>
          <share-social :view_text="false" class="share"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import ShareSocial from '@/components/activitiesSurveys/ForumSocialNetworks'

export default {
  name: 'FinalForumWhitePart',
  components: {
    TricolorLine,
    ShareSocial
  },
  computed: {
    GET_CURRENT_FORUM () {
      return this.$store.getters.GET_CURRENT_FORUM
    }
  }
}
</script>

<style lang="scss" scoped>
.white-container {
  margin-top: 2rem !important;
}

.share {
  border-top: none;
  padding-top: 0;
}

  p {
    font-size: 0.75rem;
    margin-bottom: 1.25rem;
    line-height: 150%;
    color: rgba(23, 23, 23, 0.8);

    &.p2 {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      line-height: 157%;
    }
  }

  .blue_link {
    color: #246CB7;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: #1F3245;
    /*margin-bottom: 1.625rem;*/
  }

  .forum_container__tricolor {
    margin: 1.625rem;
  }

  .section {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & article {
      padding: 1.875rem;
      border: 1px solid #EBF3FF;
      box-sizing: border-box;
      box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
      border-radius: 8px;
      width: 100%;
      background: url('../../assets/bg/bg_logo_forum_2.svg') 95% center no-repeat;
      background-size: auto 70%;
      margin-right: 2.1875rem;
      // background-size: contain;
      //    background-size: 9.7rem;

      & p {
        margin-top: .625rem;
        width: 80%;
      }
    }

    > div {
      width: 100%;
    }
  }

  .quick_contact {
    display: flex;
    justify-content: space-between;

    & p {
      margin-top: .625rem;
      max-width: 33%;
    }

    & .telephone {
      font-size: 1rem;
      line-height: 1.25;
    }
  }
  .block_text_link{
    overflow: hidden;
    max-height: none;
  }
  @media screen and (max-width: 768px) {
    .section {
      flex-direction: column;
      > article {
        background-size: inherit;
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (max-width: 420px) {
    .quick_contact {
      flex-direction: column;

      > p {
        margin-right: 0;
        max-width: 100%;
      }
    }
    .section article {
      background: none;
    }
  }

</style>
