<template>
  <div class="share-social">
    <a v-for="(social, key) in socialNetworks" target="_blank" :href="social.link" :key="`social${key}`">
      <span class="icon" :style="`background: url('${'/assets/icons/' + social.icon}') center / contain no-repeat`"></span>
    </a>
    <p v-if="view_text"><slot>Вы можете поделиться новостью в соцсетях</slot></p>
  </div>
</template>

<script>

export default {
  name: 'ForumSocialNetworks',
  props: {
    view_text: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      socialNetworks: [
        { name: 'vk', link: 'https://vk.com/forum.oprf', icon: 'vk.svg' }
        // { name: 'instagram', link: 'https://www.instagram.com/forum.oprf/', icon: 'instagram.svg' },
        // { name: 'facebook', link: 'https://www.facebook.com/pg/forum.oprf/posts/', icon: 'facebook.svg' }
        // { name: 'twitter', link: 'https://twitter.com/op_rf', icon: 'twitter.svg' },
        // { name: 'telegram', link: 'https://web.telegram.org/', icon: 'telegram.svg' }
      ]
    }
  },

  mounted () {
    //
  },

  methods: {
    //
  }

}
</script>

<style scoped lang="scss">
  .share-social {
    border-top: 1px solid #D5D6D9;
    padding-top: 2rem;
    display: flex;
    align-items: center;

    a {
      width: 2rem;
      height: 2rem;
      background: #EEF4FA;
      border-radius: 100%;
      position: relative;

      .icon {
        position: absolute;
        left: 22%;
        top: 22%;
        width: 54%;
        height: 54%;
      }
    }
    a:not(:last-of-type){
      margin-right: 0.9375rem;
    }
    p {
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 154%;
      padding-left: 1.93rem;

      color: #5E686C;
    }
  }
  @media screen and (max-width: 420px){
    .share-social {
      flex-wrap: wrap;

      p {
        padding-left: 0;
        margin-top: 0.75rem;
      }
    }

  }
</style>
