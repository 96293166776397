<template>
  <div class="member-committee">
    <div class="member-committee__members">
      <div class="card_wrapper" v-for="(member, key) in members" :key="`member${key}`"
                   :style="`background: linear-gradient(0deg, rgba(7, 22, 36, 0.75) 12.73%, rgba(28, 73, 120, 0) 79.41%), url('${$store.getters.getUrlApi}${member.url}') center / cover`">
        <p v-text="member.full_name"></p>
        <p v-text="member.position"></p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'finalForumSpeakers',
  props: {
    members: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  h2 {
    margin-bottom: 1.25rem;
  }

  .member-committee {
    font-size: .875rem;
    line-height: 1.57;

    & p {
      margin-top: .625rem;
      font-weight: 600;

      &:last-of-type {
        margin-top: 0;
        margin-bottom: 0;
        font-size: .65rem;
        font-weight: normal;
        word-spacing: 0 !important;
      }
    }

    &__members {
      display: flex;
      flex-wrap: wrap;
      filter: none !important;

      div:nth-child(n) {
        margin: 0 1.05rem 1.875rem;
        cursor: unset !important;
      }

      div:first-child {
        margin-left: 0;
      }

      div:last-child {
        margin-right: 0;
      }
      div:nth-child(6n)  {
        margin-right: 0;
      }
      div:nth-child(7n)  {
        margin-left: 0;
      }
    }
  }
  .card_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 12.1875rem;
    height: 15.0625rem;
    scroll-snap-align: start;
    cursor: pointer;
    padding: 1.3125rem 1.25rem;
    border-radius: 0.5rem;

    p {
      display: flex;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 157%;

      color: #FFFFFF;
      margin-top: auto;
      word-spacing: 10rem;
    }
  }
  @media screen and (max-width: 768px) {
    .member-committee {
      display: flex;
      overflow: hidden;

      &__members {
        div:nth-child(n) {
          margin: 0 .2rem 1.875rem !important;
        }
        div:nth-child(3n)  {
          margin-right: 0;
        }
        div:nth-child(4n)  {
          margin-left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    ::v-deep .white-container  p {
      width: 100%;
    }
  }

</style>
